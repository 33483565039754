import React from "react";
import client from "../../api/client";
import GalleryItem from "./GalleryItem";
import './Gallery.css'
import Spinner from '../../components/Spinner/Spinner';
import { TextField, Grid, InputAdornment } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';

const styles = {
  container: {
    maxWidth: "1080px",
    alignSelf: "center",
    display: "flex",
    width: "100% !important",

    "& .MuiGrid-grid-xs-12": {
      pl: {xs: 0, md: "24px"},
    }
  },
  textField: {
    height: "24px !important",
    width: "100%",
    borderRadius: "50%",
    marginBottom: "24px",
    '& label': {
      color: 'red',
    },
    '& label.Mui-focused': {
      color: 'lightgrey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'lightgrey',
      },
      '&:hover fieldset': {
        borderColor: 'lightgrey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'lightgrey',
      },
    },
  }
}

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        items: [],
        loading: true,
        search: ""
    }

    this.load = this.load.bind(this);
  }
  
  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps) {
    if (this.props.category !== prevProps.category) {
      this.load()
    }
  }

  load() {
    this.setState({...this.state, loading: true})
    client.getPosts(null, this.props.category || null)
      .then(data => { this.setState({ ...this.state, items: data, loading: false }) })
  }

  render() {
    if (this.state.loading) {
      return <Spinner show={this.state.loading} size="lg" />
    } else if (this.state.items.length === 0) {
      return <div>no posts</div>
    } else {
      return (
      // <CardGroup>
      <Grid container sx={styles.container} spacing={"24px"} columnSpacing={"24px"} rowSpacing={"24px"} alignItems={"stretch"}>
        <Grid item xs={12}>
        <TextField 
          sx={styles.textField}
          value={this.state.search} 
          onChange={(e) => this.setState({...this.state, search: e.target.value})}
          placeholder="Zoeken..."
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            },
          }}
          
        />
        </Grid>
        {
          this.state.items.filter((i) => (
            (this.state.search.length < 3) || 
            (i.name.toLowerCase().includes(this.state.search.toLowerCase())) || 
            (i.text.toLowerCase().includes(this.state.search.toLowerCase()))
          )).map((item) => {
            return <Grid item xs={12} md={4} key={item.post_id}>
              <GalleryItem

                  postId={item.post_id}
                  imgUrl={item.img_url}
                  name={item.name}
                />
            </Grid>
          })
        }
        </Grid>
      );
    }
  }
}