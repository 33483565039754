import React from "react";
import image from "../../resources/img/menu.png"
import ReactGA from "react-ga4";


export default function Order(props) {
    ReactGA.send({ hitType: "pageview", page: "/order" })
    
    const s = {textAlign: "center", marginBottom: "40px"}
    return <div>
        <h1 style={s}> Bestellen</h1>
        <p style={s}>
            Wil je graag iets bestellen? Stuur mij een mailtje op charlotteportael@gmail.com en ik neem snel contact met je op!
        </p>
        <p style={s}>
            Alle varianten zijn ook glutenvrij verkrijgbaar!
        </p>
        <p style={s}>
            Lukt het je niet je bestelling op te halen? Dan stuur ik je pakketje met plezier op. De prijs is exclusief postkosten.
        </p>
        <p style={s}>
            Verpakkingen vermijden? Dat kan! Bezorg mij je bewaarpotten en ik maak een prijs op maat op basis van het gewicht.
        </p>
        <img src={image} width="50%" 
        style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "40px"}}/>

        <div style={{height: "50px"}}></div>
    </div> 
}
