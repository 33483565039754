import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoginView from './views/login/LoginView';
import AdminDashboard from './views/dashboard/AdminDashboard/AdminDashboard';
import UserDashboard from './views/dashboard/UserDashboard/UserDashboard';
import Home from './views/site/Home';
import About from './views/site/About';
import Order from './views/site/Order';
import Header from './views/site/Header';
import Posts from './views/site/Posts';
import Contact from './views/site/Contact';
import Recipes from './views/site/Recipes';
import { updateLoginState, loginState } from './util/login';
import { Route, Routes, Navigate, HashRouter as Router, useLocation } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga4";
import CookieConsent from "react-cookie-consent";

const acceptCookies = () => {
  ReactGA.initialize("G-PK92LQGDBD");
  ReactGA.send({hitType: "pageview", path: window.location.pathname + window.location.search});
}

updateLoginState()

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

const DashboardRedirect = (props) => {
  return (loginState.loggedIn ?  
    (loginState.role === "ADMIN" ?
      <Navigate to="/admin/dashboard" /> : 
      <Navigate to="/user/dashboard" />) : 
    <Navigate to="/login" />)
};

const App = () => {
  return <>
    <React.StrictMode>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<Header ><About /></Header >}/>
            <Route path="/order" element={<Header ><Order /></Header >} />
            <Route path="/posts/:postid" element={<Header><Posts/></Header>} />
            <Route exact path="/recipes" element={<Header><Recipes/></Header>} />
            <Route path="/recipes/:category" element={<Header><Recipes/></Header>} />
            <Route path="/contact" element={<Header><Contact/></Header>} />
            <Route exact path="/dashboard" element={<DashboardRedirect/>} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/admin/dashboard" element={<AdminDashboard/>} />
            <Route path="/user/dashboard" element={<UserDashboard/>} />
          </Routes>
        </ScrollToTop>
      </Router>
    </React.StrictMode>
    <CookieConsent
      location="bottom"
      buttonText="OK"
      onAccept={acceptCookies}
      buttonStyle={{ backgroundColor: "#000000", color: "#ffffff" }}
      style={{ 
        backgroundColor: "#ffffff", 
        color: "#000000", 
        border: "1px solid black",
        maxWidth: 400,
        margin: 10
      }}
    >
      Mogen wij jouw cookies in ruil voor de onze? Op basis van jouw gebruik kunnen wij, volledig anoniem, jouw gebruik in kaart brengen om onze website beter te maken.
    </CookieConsent>
  </>
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// Register the service worker for PWA functionality
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}