import React, { useState, useEffect } from 'react';
import PostItem from '../../components/PostItem/PostItem'
import client from '../../api/client';
import Spinner from '../../components/Spinner/Spinner';
import ReactGA from "react-ga4";
import { useParams } from 'react-router-dom';


const Posts = () => {
    const [loading, setLoading] = useState(true);
    const [postInfo, setPostInfo] = useState(null);
    const params = useParams();

    useEffect(() => {
        client.getPostDetails(params?.postid)
            .then(data => {setPostInfo(data); setLoading(false) })
            .catch(err => console.log(err))
    }, [params?.postid])

    useEffect(() => {
        if (postInfo) {
            ReactGA.send({ hitType: "pageview", page: ("/" + postInfo.name) })
        }
    }, [postInfo])

    if (loading) {
        return <Spinner show={loading} />
    } else {
        return <PostItem name={postInfo.name}
                    text={postInfo.text}
                    imgUrl={postInfo.img_url}
                    tags={postInfo.tags} />
    }
};

export default Posts;